import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const BlogRollTemplate = ({ data, postCount }) => {
  const { edges: posts } = data.allMarkdownRemark;

  useEffect(() => {
    // AOS.init();
  }, []);

  const filteredPosts = postCount ? posts.slice(0, postCount) : posts;

  return (
    <div className="columns is-multiline">
      {filteredPosts.map(({ node: post }) => (
        <div className="is-parent column is-6" key={post.id}>
          <article
            className={`blog-list-item tile is-child box notification 
            }`}
          >
            <header>
              {post?.frontmatter?.featuredimage && (
                <div className="featured-thumbnail">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: post.frontmatter.featuredimage,
                      alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                      width:
                        post.frontmatter.featuredimage.childImageSharp
                          .gatsbyImageData.width,
                      height:
                        post.frontmatter.featuredimage.childImageSharp
                          .gatsbyImageData.height,
                    }}
                  />
                </div>
              )}
              <p className="post-meta">
                <Link className="title is-size-4" to={post.fields.slug}>
                  {post.frontmatter.title}
                </Link>
                <span> &bull; </span>
                <span className="subtitle is-size-5 is-block">
                  {post.frontmatter.date}
                </span>
              </p>
            </header>
            <p>
              {post.excerpt}
              <br />
              <br />
              <Link className="button" to={post.fields.slug}>
                Keep Reading →
              </Link>
            </p>
          </article>
        </div>
      ))}
    </div>
  );
};

BlogRollTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  postCount: PropTypes.number,
};

const BlogRoll = ({ postCount }) => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    gatsbyImageData(width: 120, quality: 100, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => (
      <BlogRollTemplate data={data} postCount={postCount} count={count} />
    )}
  />
);

BlogRoll.propTypes = {
  postCount: PropTypes.number,
};

export default BlogRoll;
